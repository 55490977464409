<template>
    <v-form ref="form" lazy-validation :disabled="loading" @submit.prevent="upload()">
        <v-text-field outlined label="Paste URL here" type="url" v-model="url" :rules="urlRules" required></v-text-field>
        <v-btn v-if="success" color="success"><v-icon>mdi-check</v-icon></v-btn>
        <v-btn v-else @click="upload()" :loading="loading" color="primary">Upload</v-btn>
        <v-alert v-if="error" text type="error" class="mt-4">{{ error }}</v-alert>
    </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import isURL from 'validator/es/lib/isURL'
import { fetchTask } from '@/apis'

export default {
    name: 'UrlUpload',
    props: {
        reset: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            url: '',
            urlRules: [v => !!v || 'URL is required', v => (v && isURL(v)) || 'Must be a valid URL'],
            error: '',
            success: false
        }
    },
    methods: {
        async upload() {
            if (!this.$refs.form.validate()) return
            try {
                this.loading = true
                this.$emit('uploading', true)
                const { data } = await this.$axios.post(`files/${this.$route.params.snid}/download_url`, {
                    url: this.url,
                    area: this.fileAreaType,
                    local_path: this.currentFileLocalPath || ''
                })
                const taskResult = await fetchTask(data.tkid)
                if (taskResult instanceof Error) {
                    this.error = taskResult.message
                } else {
                    this.success = true
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: this.$route.params.snid, route: this.$route, setFetchingStatus: true })
                    setTimeout(() => {
                        this.$emit('uploadComplete', true)
                    }, 2000)
                }
            } catch (error) {
                this.error = 'File upload failed due to network issues.'
            } finally {
                this.loading = false
                this.$emit('uploading', false)
            }
        }
    },
    computed: {
        ...mapState('snapshotStore', ['fileAreaType']),
        ...mapGetters('snapshotStore', ['currentFileLocalPath'])
    },
    watch: {
        reset(need) {
            if (!need) return
            this.$refs.form.reset()
            this.loading = false
            this.url = ''
            this.error = ''
            this.success = false
        }
    }
}
</script>
